<template>
    <div class="settings-card">
		<h1 class="settings__heading">Units</h1>
    
		<div class="settings">
    
			<label class="">Currency:
                <select class="settings__select" name="" id="">
                    <option value="">Cedis</option>
                    <option value="">Dollar</option>
                    <option value="">Naira</option>
                </select>
            </label>
            <label class="">Aproximations:
                <select class="settings__select" name="" id="">
                    <option value="">Tens</option>
                    <option value="">Thousands</option>
                    <option value="">Millions</option>
                </select>
            </label>
            <label for="">VAT (%):
                <input class="settings__select" type="number" value="20">      
            </label>
		</div>
       <div class="setting__button-container">
            <button class="settings__button">save</button>
       </div>

        
	</div>
</template>